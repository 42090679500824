/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face{
  font-family: BebasNeue;
  src: url('./fonts/FontsFree-Net-BebasNeueRegular.otf');
  /* font-weight: bold; */
}
@font-face{
  font-family: Poppins;
  src: url('./fonts/Poppins-Medium.otf');
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.active {
  @apply font-bold text-yellow-primary
}
.active-border {
  @apply border-b-2 border-yellow-primary
}

.hamburger-line {
  @apply w-[30px] h-[2.5px] my-2 block bg-black;
}

.hamburger-active > span:nth-child(1) {
  @apply rotate-45;
}

.hamburger-active > span:nth-child(2) {
  @apply scale-0;
}

.hamburger-active > span:nth-child(3) {
  @apply -rotate-45;
}

.menu-open {
  @apply h-[90vh]
}
.menu-close {
  @apply h-0
}

.font-judul {
  @apply font-BebasNeue font-black tracking-wide
}

.btn-active {
  @apply border-b-2 border-yellow-primary text-blue-primary
}

.img-active {
  @apply opacity-100
}

.is-input {
  @apply -top-8 -left-3 scale-75 opacity-100
}