.modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999; /* Nilai z-index yang lebih tinggi */
  }
  
  .modal-content {
    max-width: 30%;
    max-height: 70%;
    background: #fff;
    text-align: center;
    position: relative;
    overflow: auto;
    padding: 0px;
    z-index: 1000; /* Nilai z-index yang lebih tinggi */
  }
  
  /* Tambahkan aturan berikut untuk tombol close (x) */
  .close {
    position: absolute;
    top: 1px;
    right: 1px;
    font-size: 60px;
    color: #fff;
    cursor: pointer;
    z-index: 1001; /* Nilai z-index yang lebih tinggi */
  }
  
  /* Tambahkan aturan berikut untuk gambar */
.img-container {
  position: relative;
  z-index: 998; /* Nilai z-index di bawah modal */
}

.modal-image {
  width: 100%;
  height: 30%;
  max-width: none; /* Hilangkan pembatasan lebar maksimum */
  max-height: none; /* Hilangkan pembatasan tinggi maksimum */
  object-fit: contain; /* Menggunakan object-fit agar gambar tetap besar */
}